<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        outlined
        dark
        class="mb-2 mr-8"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          small
          class="mr-2"
        >
          mdi-google
        </v-icon>
        Import Google Contacts
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Import Contacts From Your Google Account</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-select
                v-model="field.groupId"
                :items="groups"
                label="Select a Group first *"
                item-text="name"
                item-value="id"
                required
                :error="!field.groupId && isFormDirty"
              />
            </v-col>
          </v-row>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="!field.groupId"
          @click="startImport"
        >
          Import
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import AuthService from '@/services/AuthService'
  const auth = new AuthService()

  export default {
    name: 'GoogleContactsImportDialog',
    props: {
      groups: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      googleClientId: process.env.VUE_APP_GOOGLE_PEOPLE_API_CLIENT_ID,
      googleApiKey: process.env.VUE_APP_GOOGLE_PEOPLE_API_KEY,
      user: null,
      dialog: false,
      field: {
        file: null,
        groupId: '',
      },
      isFormDirty: false,
      snackbar: false,
      notification: 'Unknown',
    }),

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    async mounted () {
      this.user = await auth.getUser()
      this.usremail = localStorage.getItem('username')
    },

    methods: {
      async initialize () {
        const { gapi } = window
        // console.log('gapi :>> ', gapi)
        gapi.load('client:auth2', this.initClient)
      },

      initClient () {
        // Array of API discovery doc URLs for APIs used by the quickstart
        const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/people/v1/rest']

        // Authorization scopes required by the API; multiple scopes can be
        // included, separated by spaces.
        const SCOPES = 'https://www.googleapis.com/auth/contacts.readonly'

        this.$emit('on-upload-start')
        const { gapi } = window
        gapi.client.init({
          apiKey: this.googleApiKey,
          clientId: this.googleClientId,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        }).then(() => {
          // Listen for sign-in state changes.
          gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus)

          // Handle the initial sign-in state.
          this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get())
          gapi.auth2.getAuthInstance().signIn()
          // authorizeButton.onclick = handleAuthClick
          // signoutButton.onclick = handleSignoutClick
        }, (e) => {
          // appendPre(JSON.stringify(error, null, 2))
          this.$emit('on-error')
          this.notification = 'Unable to connect with your google account'
          this.snackbar = true
        })
      },

      updateSigninStatus (isSignedIn) {
        // console.log('isSignedIn :>> ', isSignedIn)
        if (isSignedIn) {
          this.listConnectionNames()
        }
      },

      extractNames (fullName) {
        const nameParts = fullName.split(' ')
        const parts = {
          firstName: '',
          lastName: '',
        }
        if (nameParts.length) {
          if (nameParts[0].length) {
            parts.firstName = nameParts[0]
          }
          if (nameParts.length === 2 && nameParts[1].length) {
            parts.lastName = nameParts[1]
          } else if (nameParts.length > 2) {
            for (let i = 1; i < nameParts.length; i++) {
              if (i > 1) {
                parts.lastName += ' '
              }
              parts.lastName += nameParts[i]
            }
          }
        }
        return parts
      },

      listConnectionNames () {
        const { gapi } = window
        const contacts = []
        gapi.client.people.people.connections.list({
          resourceName: 'people/me',
          pageSize: 1999,
          personFields: 'names,emailAddresses,phoneNumbers',
        }).then((response) => {
          window.gapi.auth2.getAuthInstance().signOut()
          var connections = response.result.connections
          // appendPre('Connections:')
          if (connections.length > 0) {
            for (let i = 0; i < connections.length; i++) {
              var person = connections[i]
              const contact = {
                FirstName: '',
                LastName: '',
                CellPhone: '',
                UserID: this.usremail,
                GroupID: this.field.groupId,
              }
              if (person.names && person.names.length > 0) {
                // console.log('person :>> ', person)
                contact.FirstName = this.extractNames(person.names[0].unstructuredName).firstName
                contact.LastName = this.extractNames(person.names[0].unstructuredName).lastName
                if (person.phoneNumbers && person.phoneNumbers.length > 0) {
                  if (person.phoneNumbers[0] && person.phoneNumbers[0].canonicalForm) {
                    contact.CellPhone = person.phoneNumbers[0].canonicalForm
                    contacts.push(contact)
                  }
                }
              }
            }
          } else {
            // console.log('No connections found.')
            this.$emit('on-error')
            this.notification = 'No contacts found'
            this.snackbar = true
          }
          if (contacts.length) {
            this.uploadContacts(contacts)
          } else {
            this.$emit('on-error')
            this.notification = 'No contacts found'
            this.snackbar = true
          }
        })
      },

      async uploadContacts (contacts) {
        this.httpConnector.makeRequest('post', `${this.apiEndpoint}bulkimportcontact?code=${this.apiCode}`, { contacts })
          .then((res) => {
            this.$emit('on-success')
            this.dialog = false
          })
          .catch((e) => {
            this.$emit('on-error')
            this.notification = 'Unable to create the contact'
            this.snackbar = true
          })
      },

      close () {
        this.dialog = false
        this.field = {
          file: null,
          groupId: '',
        }
        this.isFormDirty = false
        this.snackbar = false
      },

      isFormValid () {
        let isValid = true
        if (!this.field.groupId) {
          isValid = false
        }
        if (!this.field.file) {
          isValid = false
        }
        return isValid
      },

      startImport () {
        this.isFormDirty = true
        if (!this.field.groupId) {
          this.notification = 'Please select a group first'
          this.snackbar = true
          return
        }

        this.initialize()
      },
    },
  }
</script>
